import Script from "next/script";

export default function Ad_728x90_Bot() {

  const isProduction = process.env.NODE_ENV === "production";


  return (
    <div className="ad-728X90-bottom">
      <div className="Leaderboard">
        {/* Load Google AdSense Script */}
         {/* <Script
          id="Adsense-id"
          data-ad-client="ca-pub-2622226100196993"
          async="true"
          strategy="beforeInteractive"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        /> */}
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-2622226100196993"
          data-ad-slot="6064669728"
        ></ins>
        {isProduction&&<Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
         (adsbygoogle = window.adsbygoogle || []).push({});
              `,
          }}
        />}
      </div>
    </div>
  );
}
